import clsx from 'clsx';
import { ButtonHTMLAttributes, forwardRef, ReactNode } from 'react';

const variants = {
	default: 'btn-default',
	secondary: 'btn-secondary',
	primary: 'btn-primary',
	success: 'btn-success',
	warning: 'btn-warning',
	danger: 'btn-danger',
};

const sizes = {
	xs: 'btn-xs',
	sm: 'btn-sm',
	md: 'btn-md',
	lg: 'btn-lg',
};

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
	children: ReactNode;
	variant?: keyof typeof variants;
	size?: keyof typeof sizes;
	isLoading?: boolean;
};

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
	(props, ref) => {
		const {
			children,
			type = 'button',
			variant = 'primary',
			size = 'md',
			className,
			isLoading,
			...rest
		} = props;

		return (
			<button
				ref={ref}
				type={type}
				className={clsx('btn', variants[variant], sizes[size], className)}
				disabled={isLoading}
				{...rest}
			>
				{children}
			</button>
		);
	}
);
