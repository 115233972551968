import React from "react";
import DashSidebar from "../sidebar";
import DashNavbar from "../navbar";
import { Outlet } from "react-router-dom";

export const DashboardRoute = () => {
  return (
    <div  className="flex justify-between">
      <div className="">
        <DashSidebar />
      </div>
      <div className="flex flex-col">
        <DashNavbar />
        <main>
          <Outlet />
        </main>
      </div>
    </div>
  );
};
