import React from "react";
import Select from "react-select";
import toast, { Toaster } from "react-hot-toast";
import { ZodType, any, z } from "zod";
import axios from "axios";
import { useState } from "react";
import { Form } from "@/components/elements";
import { InputField } from "@/components/elements";
import { Button } from "@/components/elements";
import Rating from "./CreateStars";

export type reviewForm = {
  name: string;
  rating: number;
  review: string;
  email: string;
  reviewCode: string;
  display: boolean;
};

interface CreateReviewFormProps {
  onUpdateReviews: () => void;
}

const reviewSchema = z.object({
  name: z.string().min(3, "Your fullName must be at least 3 characters"),
  rating: z.number().min(1).max(5),
  review: z.string().min(10),
  email: z.string(),
  reviewCode: z.string(),
  display: z.boolean(),
});

const CreateReviewForm:React.FC<CreateReviewFormProps> = ({ onUpdateReviews }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [display, setDisplay] = useState<boolean | null>(null);
  const onSubmit = async (data: any) => {
    try {
      setIsLoading(true);
        const response = await toast.promise(axios.post(`${process.env.REACT_APP_BASE_API_URL}/reviews`, data), {
          loading: 'creating...',
          success: <b>review created successfully!</b>,
          error: <b>the review couldn't be created!</b>,
        });
        // toast.success("review created successfully:", response.data)
        onUpdateReviews();
      } catch (error: any) {
        toast.error(error.response.data.message)
      }
      finally {
        setIsLoading(false);
      }
  };

  return (
    <div className="lg:flex lg:ml-[5rem] justify-between lg:w-[88.7%] gap-8">
      {/* Instructions Div */}
      <div className="lg:w-[50%] mb-8 xs:px-3">
  <h2 className="text-2xl font-semibold mb-4">Leave a Heartfelt Review!</h2>
  <p className="mb-2">We would love to hear your thoughts! To ensure the security of your feedback, consider providing a unique review code.</p>
  <p className="mb-2">
    If you <span className="font-bold text-[#417837]">choose to share</span> your review, it becomes a shining star visible to our audience.
  </p>
  <p>
    However, if you decide to keep it <span className="font-bold text-[#783737]">personal and private</span>, only our dedicated staff will cherish your kind words.
  </p>
  <p className="mt-4">
    Your review code serves as a special key, reminding us of the wonderful moments you experienced in our hotel. It helps us ensure that your feedback is genuine and connected to your unique experience.
  </p>
  <br />
  <h1 className="font-bold text-gray-500">thank you we appreciate for your time!</h1>
</div>

      {/* Review Form */}
      <div className="lg:w-[50%]">
        <h1 className="uppercase text-[#1DC3B2] text-2xl mb-4 xs:px-3">Add Your Review</h1>
        <Form<reviewForm, typeof reviewSchema>
          schema={reviewSchema}
          onSubmit={onSubmit}
          className="bg-[#fff] p-6 rounded-lg shadow-md"
        >
          {({ register, formState, setValue }) => (
            <div className="grid grid-cols-2 gap-4">
              <div>
                <Rating
                  maxStars={5}
                  onChange={(value) => setValue("rating", value)}
                />
              </div>
              <div>
                <InputField
                  type="text"
                  placeholder="Your Full Name"
                  registration={register("name")}
                  error={formState.errors.name}
                  className="h-8 rounded-md lg:w-[17.5rem]"
                  isLoading={isLoading}
                />
              </div>
              <div className="col-span-2">
                <textarea
                  placeholder="Write your review here..."
                  {...register("review", { required: "Review is required" })}
                  className="h-32 rounded-md p-2 w-full border-slate-300 focus:border-primary"
                />
                {formState.errors.review && (
                  <p className="text-red-500">{formState.errors.review.message}</p>
                )}
              </div>
              <div>
                <InputField
                  type="text"
                  placeholder="Enter bookingKey"
                  registration={register("reviewCode")}
                  error={formState.errors.reviewCode}
                  className="h-8 w-full rounded-md"
                  isLoading={isLoading}
                />
              </div>
              <div>
                <Select
                  options={[
                    { value: true, label: "Approved" },
                    { value: false, label: "Hidden" },
                  ]}
                  onChange={(selectedOption) => {
                    const displayValue = selectedOption?.value ?? null;
                    setDisplay(displayValue);
                    if (displayValue !== null) {
                      setValue("display", displayValue);
                    } else {
                      setValue("display", false);
                    }
                  }}
                  className="select-info w-full max-w-xs"
                  isLoading={isLoading}
                />
              </div>
              <div>
                <InputField
                  type="text"
                  placeholder="Enter the email"
                  registration={register("email")}
                  error={formState.errors.email}
                  className="h-8 w-full rounded-md"
                  isLoading={isLoading}
                />
              </div>
              <button className="btn bg-[#1DC3B2] text-xl" type="submit">Review</button>
            </div>
          )}
        </Form>
      </div>
      <Toaster position="top-center" reverseOrder={false} />
    </div>
  );
};

export default CreateReviewForm;
