export const allImages = [
    "hotel-img/Igitego-Enterance-front.jpg",
    "hotel-img/untitled-2.jpg",
    "hotel-img/untitled-3.jpg",
    "hotel-img/untitled-4.jpg",
    "hotel-img/untitled-6.jpg",
    "hotel-img/untitled-7.jpg",
    "hotel-img/untitled-8.jpg",
    "hotel-img/untitled-9.jpg",
    "hotel-img/untitled-10.jpg",
    "hotel-img/untitled-11.jpg",
    "hotel-img/untitled-12.jpg",
    "hotel-img/untitled-14.jpg",
    "hotel-img/untitled-15.jpg",
    "hotel-img/untitled-16.jpg",
    "hotel-img/untitled-17.jpg",
    "hotel-img/untitled-18.jpg",
    "hotel-img/untitled-19.jpg",
    "hotel-img/untitled-20.jpg",
    "hotel-img/untitled-21.jpg",
    "hotel-img/untitled-22.jpg",
    "hotel-img/untitled-24.jpg",
    "hotel-img/untitled-26.jpg",
    "hotel-img/untitled-27.jpg",
    "hotel-img/untitled-28.jpg",
    "hotel-img/untitled-29.jpg",
    "hotel-img/untitled-30.jpg",
    "hotel-img/untitled-31.jpg",
    "hotel-img/untitled-32.jpg",
    "hotel-img/untitled-33.jpg",
    "hotel-img/untitled-34.jpg",
    "hotel-img/untitled-35.jpg",
    "hotel-img/untitled-36.jpg",
    "hotel-img/untitled-39.jpg",
    "hotel-img/untitled-40.jpg",
    "hotel-img/untitled-42.jpg",
    "hotel-img/untitled-43.jpg",
    "hotel-img/untitled-44.jpg",
    "hotel-img/untitled-45.jpg",
    "hotel-img/untitled-46.jpg",
    "hotel-img/untitled-48.jpg",
    "hotel-img/untitled-49.jpg",
    "hotel-img/untitled-50.jpg",
    "hotel-img/untitled-51.jpg",
    "hotel-img/untitled-52.jpg",
    "hotel-img/untitled-53.jpg",
    "hotel-img/untitled-54.jpg",
    "hotel-img/untitled-55.jpg",
    "hotel-img/untitled-56.jpg",
    "hotel-img/untitled-57.jpg",
    "hotel-img/untitled-60.jpg",
    "hotel-img/untitled-62.jpg",
    "hotel-img/untitled-63.jpg",
    "hotel-img/untitled-65.jpg",
    "hotel-img/untitled-66.jpg",
    "hotel-img/untitled-68.jpg",
]