import Image from "@/components/elements/Image"
import { allImages } from "@/utils/images"

export default function Gallary() {
    const grid1Images = allImages.slice(0, Math.ceil(allImages.length / 3))
    const grid2Images = allImages.slice(Math.ceil(allImages.length / 3), Math.ceil(allImages.length / 3) * 2)
    const grid3Images = allImages.slice(Math.ceil(allImages.length / 3) * 2, allImages.length)
  return (
    <div className="container">
        <div className="grid grid-cols-3 gap-2 py-3">
            <div className="flex flex-col space-y-3">
                {grid1Images.map((image, index) => (
                    <Image 
                    loading={index<=4?"eager":"lazy"} className="w-full h-auto" src={image} 
                    alt={`Igitego hotel gallary number ${(index+1)*1}`} />
                ))}
            </div>
            <div className="flex flex-col space-y-3">
                {grid2Images.map((image, index) => (
                    <Image
                    loading={index<=4?"eager":"lazy"} className="w-full h-auto" 
                    src={image} alt={`Igitego hotel gallary number ${(index+1)*2}`} />
                ))}
            </div>
            <div className="flex flex-col space-y-3">
                {grid3Images.map((image, index) => (
                    <Image
                    loading={index<=4?"eager":"lazy"} className="w-full h-auto" 
                    src={image} 
                    alt={`Igitego hotel gallary number ${(index+1)*3}`} 
                    />
                ))}
            </div>
        </div>
    </div>
  )
}
