import FacebookSvg from '@/assets/icons/socials/facebook.svg';
import TwitterSvg from '@/assets/icons/socials/twitter.svg';
import InstagrmaSvg from '@/assets/icons/socials/instagram.svg';
import LinkedinSvg from '@/assets/icons/socials/linkedin.svg';

const socials = [
	{
		icon: FacebookSvg,
		link: 'https://www.facebook.com/igitegohotel',
	},
	{
		icon: TwitterSvg,
		link: 'https://twitter.com/hoteligitego',
	},
	{
		icon: InstagrmaSvg,
		link: 'https://www.instagram.com/igitegohotels',
	},
	{
		icon: LinkedinSvg,
		link: 'https://linkedin.com/in/igitego-hotels-abbbba218/',
	},
];

export default socials;
