import { Fragment } from 'react';
import { HashLink } from 'react-router-hash-link';
import {
	PhoneIcon,
	EnvelopeIcon,
	ArrowLongRightIcon,
	Bars3Icon,
	XMarkIcon,
} from '@heroicons/react/24/outline';
import { Popover, Transition } from '@headlessui/react';
import socials from '@/assets/data/socials';

export const NavBar = () => {
	const contacts = [
		{
			icon: <PhoneIcon className='icon-xs' />,
			label: '(+250) 789-818-818',
			link: 'tel:250789818818',
		},
		{
			icon: <EnvelopeIcon className='icon-xs' />,
			label: 'booking.igitegohotels@gmail.com',
			link: 'mailto:booking.igitegohotels@gmail.com',
		},
	];

	const menus = [
		{
			path: '/',
			label: 'Home',
		},
		// {
		// 	path: '/#rooms',
		// 	label: 'Rooms',
		// },
		{
			path: '/#other-hotels',
			label: 'Properties',
		},
		{
			path: '/gallary',
			label: 'Gallery',
		},
		{
			path: '/#about',
			label: 'About Us',
		},
		{
			path: '/contact',
			label: 'Contact',
		},
	];

	return (
		<>
			<div className='bg-dark-blue'>
				<div className='container flex items-center justify-between gap-4 py-4'>
					<div className='flex gap-4'>
						{contacts.map((contact, key) => (
							<a
								key={key}
								href={contact.link}
								className='flex items-center gap-2 text-sm font-thin text-white'
								target='_blank'
								rel='noreferrer'
							>
								{contact.icon}
								<span className='hidden md:inline-block'>{contact.label}</span>
							</a>
						))}
					</div>

					<div className='flex items-center gap-4'>
						{socials.map((social, key) => (
							<a
								key={key}
								href={social.link}
								className='text-white'
								target='_blank'
								rel='noreferrer'
							>
								<img alt={social.link} src={social.icon} className='icon-xs' />
							</a>
						))}
					</div>
				</div>
			</div>

			<Popover as={Fragment}>
				{({ open }) => (
					<nav className='card-shadow sticky top-0 py-4 px-4  z-50 bg-white'>
						<div className='container flex items-center justify-between py-6 md:py-0'>
							<div>
								<HashLink
									to='/'
									className='text-xl font-bold uppercase text-dark-blue'
								>
									Igitego <span className='text-danger'>Hotel</span>
								</HashLink>
							</div>

							<Popover.Button className='md:hidden'>
								{!open ? (
									<Bars3Icon className='icon-md text-dark-blue' />
								) : (
									<XMarkIcon className='icon-md text-dark-blue' />
								)}
							</Popover.Button>

							<div className='hidden items-center gap-10 md:flex'>
								{menus.map((menu, key) => (
									<HashLink
										key={key}
										to={menu.path}
										className='smooth-hover text-[15px] font-medium text-dark-blue hover:text-danger'
									>
										{menu.label}
									</HashLink>
								))}
								{/* <a 
								rel='noreferrer'
								href="/login" 
								className='smooth-hover text-[15px] font-medium text-dark-blue hover:text-danger'>Login</a> */}

							</div>
						</div>

						<Transition
							enter='transition duration-100 ease-out'
							enterFrom='transform scale-95 opacity-0'
							enterTo='transform scale-100 opacity-100'
							leave='transition duration-75 ease-out'
							leaveFrom='transform scale-100 opacity-100'
							leaveTo='transform scale-95 opacity-0'
						>
							<Popover.Panel>
								<div className='h-[calc(100vh-80px)] w-full flex-col justify-between gap-6 space-y-16 bg-white px-4 py-8 md:hidden'>
									<div className='space-y-5'>
										{menus.map((menu, key) => (
											<HashLink
												key={key}
												to={menu.path}
												className='smooth-hover block text-lg font-medium text-dark-blue hover:text-danger'
											>
												{menu.label}
											</HashLink>
										))}
									</div>

									{/* <HashLink
										to='/#rooms'
										className='smooth-hover flex items-center justify-center gap-2 bg-success py-6 px-8 text-[15px] font-medium text-white hover:bg-dark-blue'
									>
										Book Now
										<ArrowLongRightIcon className='icon-sm' />
									</HashLink> */}
								</div>
							</Popover.Panel>
						</Transition>
					</nav>
				)}
			</Popover>
		</>
	);
};
