export const Partner = () => {
	const partners = [
		{
			image:
				'https://preview.colorlib.com/theme/roberto/img/core-img/xp1.png.pagespeed.ic._uh9lbEDUL.webp',
			link: 'https://google.com',
		},
		{
			image:
				'https://preview.colorlib.com/theme/roberto/img/core-img/xp2.png.pagespeed.ic.XBcVmIcYDy.webp',
			link: 'https://google.com',
		},
		{
			image:
				'https://preview.colorlib.com/theme/roberto/img/core-img/xp3.png.pagespeed.ic.0k2LGF9o6c.webp',
			link: 'https://google.com',
		},
		{
			image:
				'https://preview.colorlib.com/theme/roberto/img/core-img/xp4.png.pagespeed.ic.nnZLnqKhIN.webp',
			link: 'https://google.com',
		},
		{
			image:
				'https://preview.colorlib.com/theme/roberto/img/core-img/xp5.png.pagespeed.ic.V-nYIIlCUo.webp',
			link: 'https://google.com',
		},
	];

	return (
		<section id='partner' className='bg-slate-100 pb-12'>
			<div className='container flex justify-between'>
				{partners.map((partner, key) => (
					<a
						key={key}
						href={partner.link}
						target='_blank'
						rel='noreferrer'
						className='h-10 w-10 md:h-14 md:w-14'
					>
						<img
							alt={partner.link}
							src={partner.image}
							className='h-full w-full object-contain'
						/>
					</a>
				))}
			</div>
		</section>
	);
};
