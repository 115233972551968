// CreateStars.tsx

import React, { useState } from "react";

interface StarProps {
  selected: boolean;
  onSelect: () => void;
  readOnly: boolean;
}

const Star: React.FC<StarProps> = ({ selected, onSelect, readOnly }) => (
  <span
    onClick={readOnly ? undefined : onSelect}
    style={{
      cursor: readOnly ? "not-allowed" : "pointer",
      marginRight: "5px",
      color: selected ? "gold" : "gray",
      fontSize: "24px",
    }}
  >
    {selected ? "★" : "☆"}
  </span>
);

interface RatingProps {
  maxStars: number;
  onChange: (rating: number) => void;
  selectedStars?: number;
  readOnly?: boolean;
}

const Rating: React.FC<RatingProps> = ({
  maxStars,
  onChange,
  selectedStars = 0,
  readOnly = false,
}) => {
  const [rating, setRating] = useState(selectedStars);

  const handleStarClick = (index: number) => {
    if (!readOnly) {
      setRating(index + 1);
      onChange(index + 1);
    }
  };

  return (
    <div>
      {[...Array(maxStars)].map((_, index) => (
        <Star
          key={index}
          selected={index < rating}
          onSelect={() => handleStarClick(index)}
          readOnly={readOnly}
        />
      ))}
    </div>
  );
};

export default Rating;
