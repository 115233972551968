import { Link } from 'react-router-dom';
import { ArrowLongRightIcon } from '@heroicons/react/24/outline';

export const Contact = () => {
	return (
		<section id='contact' className='bg-slate-100 py-12'>
			<div className='container'>
				<div className='contact-banner container flex flex-col justify-between rounded-sm py-6 md:h-44 md:flex-row md:items-center md:py-0'>
					<div>
						<h2 className='text-3xl font-semibold text-white md:text-4xl'>
							Contact us now!
						</h2>

						<p className='mt-4 text-base font-medium text-white/90 md:mt-2 md:text-lg'>
							Contact (+250) 789-818-818 to book directly or for advice
						</p>
					</div>

					<Link
						to='/contact'
						className='smooth-hover mt-8 flex h-14 items-center justify-center gap-1 rounded-full bg-success px-8 text-sm font-semibold text-white hover:bg-white hover:text-success md:mt-0'
					>
						Contact Now
						<ArrowLongRightIcon className='icon-sm' />
					</Link>
				</div>
			</div>
		</section>
	);
};
