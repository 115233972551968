import { HomeRoute } from '@/app/home';
import { SubscribeForm } from '@/app/subscribe/components/SubscribeForm';

export const SubscribeRoute = () => {
	return (
		<>
			<SubscribeForm />
			<HomeRoute />
		</>
	);
};
