import { HotelCard } from "@/app/home/components/HotelCard";
import hotels from "@/assets/data/hotels";

export const AllHotels = () => {
  return (
    <section id="other-hotels" className="mb-10">
      <h1 className="p-4 text-center text-3xl uppercase text-gray-400">
      Our Sister Hotels
      </h1>
      <div className="ml-[4rem] grid grid-cols-1 items-center gap-4 px-4 md:grid-cols-3 md:gap-0.5 md:p-0">
        {hotels.map((hotel, key) => (
          // <a href={hotel.link} target='_blank' rel="noreferrer">
          <HotelCard key={key} {...hotel} />
          // </a>
        ))}
      </div>
    </section>
  );
};
