import { ReactNode } from 'react';

type AppProviderProps = {
	children: ReactNode;
};

export const AppProvider = (props: AppProviderProps) => {
	const { children } = props;

	return <>{children}</>;
};
