import { SmsButton } from "@/components/elements/smsButton";
import { WhatsAppButton } from "@/components/elements/whatsapIcon";
import { ReactNode } from "react";

type AppProviderProps = {
  children: ReactNode;
};

export const AppProvider = (props: AppProviderProps) => {
  const { children } = props;

  return (
    <>
      {children}
      <div>
        <SmsButton
          phoneNumber="+250788647871"
          message="Hi! I would like to know more about your services."
        />
        <WhatsAppButton
          phoneNumber="+250788647871"
          message="Hi! I would like to know more about your services."
        />
      </div>
    </>
  );
};
