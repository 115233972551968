import { HomeModernIcon } from "@heroicons/react/24/outline";

import Banner1 from "@/assets/images/rooms/deluxe/1.jpg";
import Banner2 from "@/assets/images/rooms/double/1.jpg";
import Banner3 from "@/assets/images/building.jpg";

export const AboutUs = () => {
  const services = [
    {
      icon: <HomeModernIcon className="mx-auto h-11 w-11 text-success" />,
      label: "Rooms",
      image: "/service/room3.jpeg",
      desc: "Experience comfort and luxury in every stay at our hotel. Our thoughtfully designed rooms offer a perfect blend of modern amenities and cozy elegance, providing a peaceful retreat for both leisure and business travelers.",
    },
    {
      icon: <HomeModernIcon className="mx-auto h-11 w-11 text-success" />,
      image: "/service/wedding2.jpeg",
      label: "weddings",
      desc: "Celebrate your special day in our enchanting wedding hall, where dreams come to life. With elegant decor and impeccable service, we create the perfect backdrop for your love story, ensuring an unforgettable beginning to your forever.",
    },
    {
      icon: <HomeModernIcon className="mx-auto h-11 w-11 text-success" />,
      label: "Restaurant",
      image: "/service/restaurant2.jpeg",
      desc: "Enjoy a culinary journey at our restaurant, where exquisite flavors and a warm ambiance come together. From mouthwatering appetizers to decadent desserts, savor a diverse menu crafted with passion and precision.",
    },
    {
      icon: <HomeModernIcon className="mx-auto h-11 w-11 text-success" />,
      label: "Bar",
      image: "/service/bar2.jpeg",
      desc: " Immerse yourself in the vibrant atmosphere of our bar services. Sip on expertly crafted cocktails and savor a selection of fine beverages. Whether it's a casual evening or a lively celebration, our bar is the ideal spot to unwind, socialize, and enjoy a memorable night out.",
    },
  ];
  return (
    // <section id='about' className='py-14 mt-[1000px]'>
    // 	<div className='container'>
    // 		<div className='flex flex-col items-center gap-12 md:flex-row'>
    // 			<div className='md:basis-1/2'>
    // 				<p className='text-sm font-medium uppercase text-success'>
    // 					About Us
    // 				</p>

    // 				<h2 className='mt-2 text-3xl font-semibold text-dark-blue md:text-5xl'>
    // 					Welcome To Igitego Hotel
    // 				</h2>

    // 				<p className='my-6 text-xl font-light leading-relaxed text-slate-700 md:my-10'>
    // 					One of the best hotels in Kigali city Rwanda, seeks to give the
    // 					business or leisure traveler a relaxing home-away from-home
    // 					atmosphere.
    // 				</p>

    // 				<p className='text-base font-medium text-slate-600'>
    // 					Manager Director:{' '}
    // 					<a href='mailto:deodathe2004@gmail.com' className='text-success hover:underline'>Deodathe Ndazivunye</a>
    // 				</p>
    // 			</div>

    // 			<div className='flex gap-2 md:basis-1/2'>
    // 				<div className='flex-1 space-y-2'>
    // 					<div className='h-52 w-full overflow-hidden rounded-sm'>
    // 						<img
    // 							alt='banner 1'
    // 							src={Banner1}
    // 							className='smooth-hover h-full w-full rounded-sm object-cover hover:scale-110'
    // 						/>
    // 					</div>

    // 					<div className='h-52 w-full overflow-hidden rounded-sm'>
    // 						<img
    // 							alt='banner 1'
    // 							src={Banner2}
    // 							className='smooth-hover h-full w-full rounded-sm object-cover hover:scale-110'
    // 						/>
    // 					</div>
    // 				</div>

    // 				<div className='flex-1 overflow-hidden rounded-sm'>
    // 					<img
    // 						alt='banner 3'
    // 						src={Banner3}
    // 						className='smooth-hover h-full w-full rounded-sm object-cover hover:scale-110'
    // 					/>
    // 				</div>
    // 			</div>
    // 		</div>

    // 		<div className='mt-12 flex flex-wrap justify-between gap-8'>
    // 			{services.map((service, key) => (
    // 				<div
    // 					key={key}
    // 					className='card-shadow flex-1 rounded-sm p-5 text-center'
    // 				>
    // 					{service.icon}
    // 					<p className='mt-2 text-sm font-medium text-dark-blue'>
    // 						{service.label}
    // 					</p>
    // 				</div>
    // 			))}
    // 		</div>
    // 	</div>
    // </section>
    <section id="about" className=" py-14 md:w-screen">
      <div className="container">
        <div className="container-about flex flex-col  items-center justify-center gap-12 md:flex-row">
          <div className="py-4 md:basis-1/2">
            <p className="mb-4 text-center text-sm font-medium uppercase text-white">
              About Us
            </p>

            <h2 className="mt-2 text-center text-3xl font-semibold text-white md:text-5xl">
              Welcome To Igitego Hotel
            </h2>

            <p className="font-raleway my-6 text-center   text-xl font-normal  leading-relaxed text-white md:my-10">
              One of the best hotels in Kigali city Rwanda, seeks to give the
              business or leisure traveler a relaxing home-away from-home
              atmosphere.
            </p>

            <p className=" white font-serif text-2xl  font-medium text-white">
              Manager Director:{" "}
              <a
                href="mailto:deodathe2004@gmail.com"
                className="font-extrabold text-white hover:underline"
              >
                Deodathe Ndazivunye
              </a>
            </p>
          </div>
        </div>
        <h1 className="mt-4 text-center text-3xl font-normal text-gray-400">
          Our Services
        </h1>
        <div className="mt-2 grid grid-cols-1 flex-wrap justify-between gap-8 md:grid-cols-2">
          {services.map((service, key) => (
            <div key={key} className="flex-1 p-5">
              <div className="relative h-60 overflow-hidden">
                <img
                  src={service.image}
                  alt={service.label}
                  className="h-full w-full  object-cover"
                />
              </div>
              <h1 className="mt-2 text-sm font-medium text-dark-blue">
                {service.label}
              </h1>
              <p className="font-raleway font-extralight text-black">
                {service.desc}
              </p>
            </div>
          ))}
        </div>
        <h1 className="text-center">
          For more image check in{" "}
          <a href="/gallary" className="text-[#1dc3b2]">
            gallery
          </a>
        </h1>
      </div>
    </section>
  );
};
