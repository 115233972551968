import React, { useState, useEffect } from "react";
import axios from "axios";
import Rating from "./CreateStars";

interface GetReviewsProps {
  onUpdateReviews: () => void;
}

export type reviewForm = {
  id: string;
  name: string;
  rating: number;
  review: string;
  email: string;
  reviewCode: string;
  display: boolean;
  createdAt: string;
};

const GetReviews: React.FC<GetReviewsProps> = ({ onUpdateReviews }) => {
  const [reviews, setReviews] = useState<reviewForm[]>([]);
  const [showFullDescription, setShowFullDescription] = useState(false);

  const getReviews = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_API_URL}/reviews`
      );
      console.log(response.data.reviews);
      setReviews(response.data.reviews);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getReviews();
  }, [onUpdateReviews]);

  const formatDateTime = (dateTimeString: string) => {
    const options: Intl.DateTimeFormatOptions = {
      hour: "numeric",
      minute: "numeric",
      day: "numeric",
      month: "numeric",
      year: "numeric",
    };

    const dateTime = new Date(dateTimeString);
    return dateTime.toLocaleString(undefined, options);
  };

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  return (
    <div className="mx-auto w-[88.7%]">
    <div className="text-center mt-4">
      <p className="text-[#1DC3B2] text-base">
        Swipe left and right to view more reviews{" "}
        <span role="img" aria-label="swipe">
          🔄
        </span>
      </p>
    </div>
      <div className="carousel w-full">
        {reviews.map((review: reviewForm) => (
          <div
            key={review.id}
            className="carousel-item card m-[2rem] lg:w-96 text-[#933030] overflow-hidden rounded-lg bg-white shadow-xl"
          >
            <div className="card-body p-6">
              <div className="mb-4 flex items-center justify-between">
                <h2 className="text-xl font-semibold font-italic ">{review.name}</h2>
                <span className="text-sm text-gray-500">
                  {formatDateTime(review.createdAt)}
                </span>
              </div>
              <p className="mb-4 text-gray-500">
                {review.review.length > 70 && !showFullDescription
                  ? `${review.review.slice(0, 70)}...`
                  : review.review}
                {review.review.length > 70 && (
                  <span
                    className="cursor-pointer text-sm font-thin text-[#1dc3b2]"
                    onClick={toggleDescription}
                  >
                    {showFullDescription ? "show less" : "read more"}
                  </span>
                )}
              </p>
              <div className="flex items-center">
                {/* Use the Rating component to display stars */}
                <Rating
                  maxStars={5}
                  onChange={() => {}}
                  selectedStars={review.rating}
                  readOnly={true}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default GetReviews;
