import { useState } from "react";
import { AboutUs } from "@/app/home/components/AboutUs";
import { Hero } from "@/app/home/components/Hero";
import { AllHotels } from "@/app/home/components/OtherHotel";
import { OurRooms } from "@/app/home/components/OurRooms";
import CreateReviewForm from "../components/CreateReviewForm";
import GetReviews from "../components/GetReviews";
import Confetti from "react-confetti";
import Countdown from "react-countdown";
export interface hotelProps {
  id: string;
  name: string;
  link: string;
  desc: string;
  cover: string;
}

const Popup = ({ message, children }: any) => {
  return (
    <div className="popup-container">
      <div className="popup">
        <p className="font-raleway mt-2 bg-gradient-to-r from-[#ffffff] to-[#ff0000] bg-clip-text text-center text-3xl text-transparent">
          {message}{" "}
          <span className=" bg-none  text-white opacity-100">🎅🏻🎉</span>
        </p>
        <div>{children}</div>
      </div>
    </div>
  );
};

const CountdownRenderer = ({
  hours,
  days,
  year,
  minutes,
  seconds,
  completed,
}: any) => {
  if (completed) {
    return null;
  } else {
    return (
      <div className=" mt-4  flex items-center justify-center ">
        <div
          className="font-raleway text-thin 
		  border border-black  px-8 py-2
			text-center  text-black"
        >
          <div className="flex w-full items-center justify-center">
            <img src="/noel1.svg" alt="chrImg" width={50} height={50} />
          </div>
          <div className="flex items-center justify-center">
            <p className="flex  flex-col">
              {days + " :"}
              <span className="mr-2 text-left text-zinc-300">
                {days === 1 ? "day" : "days"}
              </span>
            </p>
            <p className="flex flex-col">
              {minutes + " :"}
              <span className="mr-2  text-zinc-300">minutes</span>
            </p>{" "}
            <p className="flex flex-col">
              {seconds}
              <span className="text-zinc-300">seconds</span>
            </p>
          </div>
        </div>
      </div>
    );
  }
};
export const HomeRoute = () => {
  const currentDate = new Date();
  const targetDate = new Date(currentDate.getFullYear() + 1, 0, 1);
  const isHolidaySeason =
    (currentDate.getMonth() === 11 &&
      currentDate.getDate() >= 22 &&
      currentDate.getDate() <= 31) ||
    (currentDate.getMonth() === 0 &&
      currentDate.getDate() >= 1 &&
      currentDate.getDate() <= 15);
  const [reviewsUpdated, setReviewsUpdated] = useState(false);

  const handleUpdateReviews = () => {
    setReviewsUpdated(!reviewsUpdated);
  };
  return (
    <>
      {/* <Hero /> */}
      {isHolidaySeason && (
        <Popup message="Happy New Year and Merry Christmas">
          <Countdown date={targetDate} renderer={CountdownRenderer} />
          <Confetti
            drawShape={(ctx) => {
              ctx.beginPath();
              for (let i = 0; i < 22; i++) {
                const angle = 0.35 * i;
                const x = (0.2 + 1.5 * angle) * Math.cos(angle);
                const y = (0.2 + 1.5 * angle) * Math.sin(angle);
                ctx.lineTo(x, y);
              }
              ctx.stroke();
              ctx.closePath();
            }}
          />
        </Popup>
      )}
      <h1 className="pt-4 text-center text-3xl font-normal text-gray-400">
        Check availability and book available room
      </h1>

      {/*//@ts-ignore */}
      <booking-widget id="1523" multiproperty="false"></booking-widget>
      <CreateReviewForm onUpdateReviews={handleUpdateReviews} />
      <GetReviews onUpdateReviews={handleUpdateReviews} />
      <OurRooms />
      <AboutUs />
      <AllHotels />
    </>
  );
};
