import { useState } from "react";

interface ImageProps{
  src: string;
  alt: string;
  loading?: "eager" | "lazy";
  className?: string;
}
export default function Image(props: ImageProps) {
  const { src, alt, loading, className } = props;
  const [isLoading, setIsLoading] = useState(true);
  return (
    <div className="relative w-full">
      {isLoading && <div className='w-full h-[250px] bg-gray-200 rounded-sm animate-pulse'></div>}
      <img
        loading={loading}
        className={className}
        src={src}
        alt={alt}
        onLoad={() => setIsLoading(false)}
      />
    </div>
  )
}
