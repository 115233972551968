import clsx from 'clsx';
import { UseFormRegisterReturn } from 'react-hook-form';
import { FieldWrapper, FieldWrapperPassThroughProps } from '../fieldwrapper';

type InputFieldProps = FieldWrapperPassThroughProps & {
	type?: 'text' | 'email' | 'number' | 'date' | 'file' | 'password';
	className?: string;
	placeholder?: string;
	autoFocus?: boolean;
	disabled?: boolean;
	registration?: Partial<UseFormRegisterReturn>;
	isLoading?: boolean;
	onChange?: (e: any) => void;
	min?: number | string; 
  };
  

export const InputField = (props: InputFieldProps) => {
	const {
		type,
		label,
		placeholder,
		autoFocus = false,
		disabled = false,
		className = '',
		registration,
		error,
		isLoading = false,
		onChange,
		min,
	} = props;

	return (
		<FieldWrapper label={label} error={error}>
			<input
				type={type}
				className={clsx(
					'form-control',
					error?.message
						? 'border-danger focus:border-danger'
						: 'border-slate-300 focus:border-primary',
					className
				)}
				autoComplete='on'
				autoFocus={autoFocus}
				placeholder={placeholder}
				disabled={disabled || isLoading}
				{...registration}
				min={min}
				onChange={onChange? onChange: ()=>{}}
			/>
		</FieldWrapper>
	);
};
