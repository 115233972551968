import { ArrowLongRightIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';

type HotelCardProps = {
	name: string;
	link: string;
	desc: string;
	cover: string;
};

export const HotelCard = (props: HotelCardProps) => {
	const { name, desc, link, cover } = props;
	const [showFullDescription, setShowFullDescription] = useState(false);

	const toggleDescription = () => {
	  setShowFullDescription(!showFullDescription);
	};

	return (
		<div className="max-w-sm bg-white  rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 ">
      <a href={link}>
        <img className="rounded-t-lg" src={cover} alt="" />
      </a>
      <div className="p-3">
        <a href={link}>
          <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
            {name}
          </h5>
        </a>
        <p className="mb-3  text-gray-700 dark:text-gray-400 ">
          {showFullDescription ? desc : `${desc.slice(0, 70)}...`}
          
            <span
              className="text-[#1dc3b2] text-sm font-thin cursor-pointer"
              onClick={toggleDescription}
            >
             {showFullDescription ? "show less" : "read more" } 
            </span>
          
        </p>
        <a
          href={link}
          className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-[#1dc3b2] rounded-lg hover:bg-green-700 transition-all ease-in-out 1s focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          Visit
          <svg
            className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 10"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M1 5h12m0 0L9 1m4 4L9 9"
            />
          </svg>
        </a>
      </div>
    </div>
	);
};
