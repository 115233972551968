import { WifiIcon } from '@heroicons/react/24/outline';

const services = [
	{
		label: 'Free Wi-Fi',
		icon: <WifiIcon className='h-7 w-7 text-dark-blue' />,
	},
	{
		label: 'Bathrooms',
		icon: <WifiIcon className='h-7 w-7 text-dark-blue' />,
	},
	{
		label: 'Flat Screen',
		icon: <WifiIcon className='h-7 w-7 text-dark-blue' />,
	},
	{
		label: 'Wardrobes',
		icon: <WifiIcon className='h-7 w-7 text-dark-blue' />,
	},
	{
		label: 'Seating Area',
		icon: <WifiIcon className='h-7 w-7 text-dark-blue' />,
	},
	{
		label: 'Balcony',
		icon: <WifiIcon className='h-7 w-7 text-dark-blue' />,
	},
];

export default services;
