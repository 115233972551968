import * as z from 'zod';
import { Button, Form, InputField } from '@/components/elements';
import { BellIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import { SubscribePayload } from '@/app/subscribe/types';

const schema = z.object({
	name: z.string().min(1, 'Name required'),
	email: z.string().min(1, 'Email required').email(),
	phone: z.string().min(1, 'Phone required'),
});

export const SubscribeForm = () => {
	const isLoading = false;

	return (
		<div className='fixed top-0 right-0 bottom-0 left-0 z-[55] flex h-screen w-full items-center justify-center overflow-scroll bg-white md:bg-black/80'>
			<div className='block bg-white p-5 text-center md:rounded-md md:p-10'>
				<div>
					<h2 className='text-2xl font-semibold uppercase text-black md:text-3xl'>
						Join Our News Letter
					</h2>

					<p className='mt-3 text-base font-normal leading-5 text-gray'>
						Subscribe our newsletter to receive the latest news and{' '}
						<br className='hidden md:inline-block' /> exclusive offers every
						week. No spam.
					</p>
				</div>

				<Form<SubscribePayload, typeof schema>
					schema={schema}
					className='my-12 space-y-5'
					onSubmit={payload => console.log('payload', payload)}
				>
					{({ register, formState }) => (
						<>
							<div className='space-y-3 text-left'>
								<InputField
									type='text'
									placeholder='Your Name'
									registration={register('name')}
									error={formState.errors.name}
									isLoading={isLoading}
									autoFocus
									className='h-14 rounded-md'
								/>

								<InputField
									type='email'
									placeholder='Your Email'
									registration={register('email')}
									error={formState.errors.email}
									isLoading={isLoading}
									className='h-14 rounded-md'
								/>

								<InputField
									type='text'
									placeholder='Your Phone'
									registration={register('phone')}
									error={formState.errors.phone}
									isLoading={isLoading}
									className='h-14 rounded-md'
								/>
							</div>

							<Button
								type='submit'
								variant='success'
								className='w-full gap-2 rounded-md py-4 text-base uppercase'
							>
								<BellIcon className='icon-sm' />
								Subscribe
							</Button>
						</>
					)}
				</Form>

				<Link
					to='/'
					className='font-semibold uppercase text-dark-blue underline'
				>
					No Thanks
				</Link>
			</div>
		</div>
	);
};
