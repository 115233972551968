import { AppLayout } from "@/components/layouts";
import { Navigate, createBrowserRouter } from "react-router-dom";
import { DashboardRoute } from "./app/dashboard";
import { HomeRoute } from "@/app/home";
import { RoomRoute } from "./app/room";
import { ContactRoute } from "./app/contact";
import { SubscribeRoute } from "@/app/subscribe";
import Gallary from "./app/gallary";
import { LoginRoute } from "./app/login";
import { SignupRoute } from "./app/signup";
import { Content } from "./app/dashboard";
import {Booking} from "./app/dashboard"
// import {BookForm } from "./app/books/Form"

const Router = createBrowserRouter([
  {
    element: <AppLayout />,
    children: [
      { path: "", element: <HomeRoute /> },
      { path: "subscribe", element: <SubscribeRoute /> },
      { path: "room/:slug", element: <RoomRoute /> },
      { path: "/login", element: <LoginRoute /> },
      { path: "/signup", element: <SignupRoute /> },
      { path: "/gallary", element: <Gallary /> },
      { path: "contact", element: <ContactRoute /> },
      // { path: "/room/:slug/books", element: <BookForm  /> }
    ],
  },
  { element: <DashboardRoute />,
    children: [
      {path: "/dashboard", element: <Content />},
	  {path: "/dashboard/booking", element: <Booking />},
	] },
  { path: "*", element: <Navigate to="/" /> },
]);

export default Router;
